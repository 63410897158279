import React from "react";
// import AlertBlock from "../alert/src/AlertBlock";
import AdminLoginController, { Props } from './AdminLoginController';
class AdminLogin extends AdminLoginController {
  static instance: AdminLogin;
  constructor(props: Props) {
    super(props);
    AdminLogin.instance = this;
  }

  async componentDidMount(): Promise<void> {
    // this.getCategory();
  }

  render() {

    const { formData, validation, resultMessage, passwordShown } = this.state;

    return (
      <>
        <div className='signin'>
          <div className='signin-box'>
            <img src='/images/logo.png' className='logo' alt="logo" />
            {resultMessage && <p className='formErrors'>{resultMessage}</p>}
            {validation.email && <div className='formErrors text-start'>{validation.email}</div>}
            <input className='signin-input' type="text" placeholder='Enter Email ID' value={formData.email} onChange={this.handelInput} name="email" />
            <div className='position-relative'>
              {validation.password && <div className='formErrors text-start'>{validation.password}</div>}
              <input className='signin-input' type={passwordShown ? "text" : "password"} id="password" name="password" placeholder="Password" onChange={this.handelInput} value={formData.password} />
              <img src={passwordShown ? "/images/eyeshow.png" : "/images/eyehide.png"} className="position-absolute password-hide" alt="password" onClick={() => { this.togglePassword() }} />
            </div>
            <div className="d-flex align-items-center justify-content-between mt-1 mb-4">
              <div>
                <input className="mb-0" type="checkbox" name="checked" checked={formData.checked} onChange={e => this.setState({ formData: { ...this.state.formData, [e.target.name]: e.target.checked } })} />
                <label className='ms-1 mb-0'>Remember me</label>
              </div>
              {/* <a href="/forgot-password"><span className='forget-link'>Forgot Password?</span></a> */}
            </div>
            <button type="button" className='submit' onClick={() => { this.handleSubmit() }}>Next</button>
          </div>
        </div>
      </>
    );
  }
}
// Customizable Area End
export default AdminLogin;
