"use strict";

const baseURL ="__MARKER_FOR_BACKEND_URL_REPLACEMENT";

//dev url
     //const baseURL =
   //'https://retyrnv20-46384-ruby.b46384.dev.eastus.az.svc.builder.cafe';


  //stage url
  // const baseURL =
    //'https://retyrnv20-46384-ruby.b46384.stage.eastus.az.svc.builder.ai';



Object.defineProperty(exports, "__esModule", {
  value: true
});

exports.baseURL = baseURL; 
