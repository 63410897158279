// App.js - WEB
import React, { Component } from 'react';
import { View } from "react-native";
import AdminLogin from "../../components/src/AdminLogin";
import AdminChat from "../../components/src/AdminChat";
import WebRoutesGenerator from "../../components/src/NativeWebRouteWrapper";
import { ModalContainer } from "react-router-modal";
import firebase from 'firebase';
const routeMap = {
  Home: {
    component: AdminLogin,
    path: '/login',
    exact: true
  },
  Chat: {
    component: AdminChat,
    path: '/',
    exact: true
  },

};
const firebaseAPI = firebase.initializeApp({
  apiKey: 'AIzaSyDgl9aTbKMdRZ9-ijSZRionh3V591gMJl4',
  authDomain: 'rnmasterapp-c11e9.firebaseapp.com',
  databaseURL: 'https://rnmasterapp-c11e9.firebaseio.com',
  projectId: 'rnmasterapp-c11e9',
  storageBucket: 'rnmasterapp-c11e9.appspot.com',
  messagingSenderId: '649592030497',
  appId: '1:649592030497:web:7728bee3f2baef208daa60',
  measurementId: 'G-FYBCF3Z2W3'
});

class App extends Component {
  render() {
    const defaultAnalytics = firebaseAPI.analytics();
    defaultAnalytics.logEvent('APP_Loaded');

    return ( <View style={{ height: '100vh', width: '100vw' }}>
    {WebRoutesGenerator({ routeMap })}
    <ModalContainer />
  </View>);
  }
}

export default App;
