import React from 'react';
import { BlockComponent } from "../../framework/src/BlockComponent";
import axios from 'axios';
export const configJSON = require("../../framework/src/config.js");
// Customizable Area Start
const urlConfig = require("../../framework/src/config");

// Customizable Area End

export interface Props {
    navigation: any;
    id: string;
    // Customizable Area Start
    classes: any;
    discussions:any
    // Customizable Area End
}

interface S {
    formData: any;
    validation: any;
    resultMessage: any;
    passwordShown: boolean
}

interface SS {
    id: any;
    // Customizable Area Start
    // Customizable Area End
}

export default class AdminLoginController extends BlockComponent<
    Props,
    S,
    SS
    > {

    constructor(props: Props) {
        super(props);
    
        this.state = this.getEmptyState();
        // Customizable Area End
    
        // Customizable Area Start
        // Customizable Area End
    }
    
        // Customizable Area Start
    getEmptyState = () => ({
        formData: {
            email: "",
            password: "",
            checked: ''
        },
        validation: {
            email: "",
            password: ""
        },
        resultMessage: '',
        passwordShown: false
    });
    
    handelInput = (e: any) => {
        this.setState({
            formData: { ...this.state.formData, [e.target.name]: e.target.value },
        });
    };
    checkValidation() {
        const formdata = this.state.formData;
        const errors = this.state.validation;
        // email validation
        const emailCond = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
        if (!formdata.email) {
          errors.email = "Email is required";
        } else if (!formdata.email.match(emailCond)) {
          errors.email = "Please enter valid email address";
        } else {
          errors.email = "";
        }
    
        //password validation
        const password = formdata.password;
        const passReg = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{6})/;
        if (!password) {
          errors.password = "Password is required";
        }
        // else if (!password.match(passReg)) {
        //   errors.password = "Password must be at least 6 characters and must include at least one upper case letter, one lower case letter, one special character and one numeric digit.";
        // }
        else {
          errors.password = "";
        }
        this.setState({
            validation: { ...this.state.validation, errors },
          });
          
        if(errors.email === '' && errors.password === ''){
          return true;
        }else{
          return false;
        }
    }

    handleSubmit = () => {
        const isSuccess = this.checkValidation();
        if(isSuccess) {
            this.login();
        }
      }

    // Customizable Area Start
    async componentDidMount() {
        super.componentDidMount();
    }

    async login(){
        const baseUrl = urlConfig.baseURL;
        const params = {
            headers: {
              "Content-Type": 'application/json',
              "Access-Control-Allow-Origin": '*'
            }
          };
        const loginData = {
            data: {
               email: this.state.formData.email,
               password: this.state.formData.password 
                }
            }

        await axios.post(
            `${baseUrl}/bx_block_admin/admin_login`, loginData, params
        ).then(response => {
           if(response.status === 200){
            localStorage.setItem('token', response.data.meta.token);
            window.location.href = '/';
           }else{
            this.setState({
                resultMessage: response.data.admin_account
            })
           }
            
        })
        .catch(error => {
            this.setState({
                resultMessage: error.response.data.admin_account
            })
        })
    }

    togglePassword(){
        this.setState({
            passwordShown: !this.state.passwordShown
        })
    }

}
